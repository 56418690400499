/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, createContext, useContext, Dispatch, SetStateAction } from 'react';
import { LayoutSplashScreen } from '../../../../compliancemasters/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { WithChildren } from '../../../../compliancemasters/helpers';
import { client } from '../../../../shared';
import { ICompany } from '../../../../models';

export interface IPackage {
    id: string;
    numberOfDrivers: number;
}

interface IRegisterProps {
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    passwordConfirmation?: string,
    company?: string,
    birth?: Date,
    state?: string,
    driver_type?: string,
    role?: 'Owner' | 'Admin' | 'Member' | 'SuperAdmin' | 'SalesRep',
    social_security_number?: string,
    phone_number?: string,
    drivers_license_number?: string,
    date_of_birth?: string,
    street_address?: string,
    city?: string,
    zip_code?: string,
}


type AuthContextProps = {
    auth: AuthModel | undefined;
    saveAuth: (auth: AuthModel | undefined) => void;
    currentUser: UserModel | undefined;
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
    logout: () => void;
    login: (email: string, password: string) => Promise<any>;
    register: (data: IRegisterProps) => Promise<any>;
    getUser: () => Promise<any>;
    searchUsers: (email?: string) => Promise<any>;
    createCompany: (
        name: string,
        address1: string,
        address2: string,
        zipCode: string,
        city: string,
        country: string,
        packages: IPackage[],
        owner_driver: boolean,
        only_driver: boolean,
        dot_number: string,
        product_id?: string,
        social_security_number?: string,
        phone_number?: string,
        driver_license_number?: string,
        date_of_birth?: string,
        requires_custom_pricing?: boolean,
        has_dot_compliant_files?: boolean
    ) => Promise<any>;
    emailUserForSignUp: (email: string, compamy: string, driverType?: string, product_id?: string) => Promise<any>;
    promoteUserToAdmin: (email: string) => Promise<any>;
    deleteUser: (email: string, type: 'soft' | 'hard') => Promise<any>;
    getCompanies: () => Promise<any>;
    getCompany: (id: number) => Promise<any>;
    updateCompany: (company: Partial<ICompany>) => Promise<any>;
    setFormsDone: (memberId: number) => Promise<void>
    toggleFormsDone: (memberId: number, forms_done: boolean) => Promise<any>
    getCreds: () => Promise<any>
};

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => { },
    currentUser: undefined,
    setCurrentUser: () => { },
    logout: () => { },
    login: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    getUser: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    searchUsers: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    register: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    createCompany: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    emailUserForSignUp: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    promoteUserToAdmin: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    deleteUser: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    getCompanies: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    getCompany: (id: number) => new Promise((resolve: any) => resolve()) as Promise<any>,
    updateCompany: (company: Partial<ICompany>) => new Promise((resolve: any) => resolve()) as Promise<any>,
    setFormsDone: () => new Promise((resolve: any) => resolve()) as Promise<any>,
    toggleFormsDone: (memberId: number, forms_done: boolean) => new Promise((resolve: any) => resolve()) as Promise<any>,
    getCreds: () => new Promise((resolve: any) => resolve()) as Promise<any>
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
    return useContext(AuthContext);
};

function AuthProvider({ children }: WithChildren) {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

    function saveAuth(auth: AuthModel | undefined) {
        setAuth(auth);
        if (auth) {
            authHelper.setAuth(auth);
        } else {
            authHelper.removeAuth();
        }
    }

    function logout() {
        /*
      Logs user out by removing any tokens and the users info
    */
        saveAuth(undefined);
        setCurrentUser(undefined);
        window.localStorage.removeItem('compliance_master_refer_path')
    }

    function login(email: string, password: string) {
        /*
    logins in user
    */
        return client('auth/api/token/', {
            body: { email, password }
        });
    }

    function createCompany(
        name: string,
        address1: string,
        address2: string,
        zipCode: string,
        city: string,
        country: string,
        packages: IPackage[],
        owner_driver: boolean,
        only_driver: boolean,
        dot_number: string,
        product_id?: string,
        social_security_number?: string,
        phone_number?: string,
        driver_license_number?: string,
        date_of_birth?: string,
        requires_custom_pricing?: boolean,
        has_dot_compliant_files?: boolean
    ) {
        return client('auth/api/company/', {
            body: {
                name,
                address1,
                address2,
                zipCode,
                city,
                country,
                package: packages,
                owner_driver,
                dot_number,
                only_driver,
                product_id,
                social_security_number,
                phone_number,
                driver_license_number,
                date_of_birth,
                requires_custom_pricing,
                has_dot_compliant_files
            }
        });
    }

    function emailUserForSignUp(email: string, company: string, driverType?: string, product_id?: string) {
        return client('auth/api/company/email_driver_for_signup/', {
            body: { email, driverType, product_id, company }
        });
    }

    function getUser() {
        return client('auth/api/user/');
    }

    function searchUsers(email?: string) {
        return client(`auth/api/user/search/`, {
            body: { email: email ? email : '' }
        }, true, 'POST');
    }

    function promoteUserToAdmin(email: string) {
        return client('auth/api/user/create-admin/', {
            body: { email }
        });
    }

    function deleteUser(email: string, type: 'soft' | 'hard') {
        return client('auth/api/user/delete_user/', {
            body: { email, delete_type: type }
        });
    }

    function register(data: IRegisterProps) {
        return client('auth/api/user/', {
            body: {
                email: data.email,
                first_name: data.firstname,
                last_name: data.lastname,
                password: data.password,
                passwordConfirmation: data.passwordConfirmation,
                company: data.company,
                birth: data.birth,
                state: data.state,
                driver_type: data.driver_type,
                role: data.role,
                social_security_number: data.social_security_number,
                phone_number: data.phone_number,
                drivers_license_number: data.drivers_license_number,
                street_address: data.street_address,
                city: data.city,
                zip_code: data.zip_code,
            }
        });
    }

    function setFormsDone(memberId: number) {
        return client('auth/api/user/set-forms-done/', {
            body: { memberId }
        });
    }

    function toggleFormsDone(memberId: number, forms_done: boolean) {
        return client('auth/api/user/update-forms-done/', {
            body: { member_id: memberId, forms_done }
        });
    }

    function requestPassword(email: string) {
        /* nothing here for now */
        return client('/nothing-for-now');
    }

    async function getCompanies() {
        return client('auth/api/company/');
    }

    async function updateCompany(company: Partial<ICompany>) {
        return client(`auth/api/company/${company.id}/`, {
            body: company
        }, true, 'PUT');
    }

    async function getCreds() {
        return client('auth/api/user/get-creds/');
    }

    async function getCompany(id: number) {
        return client(`auth/api/company/${id}/`);
    }

    return (
        <AuthContext.Provider
            value={{
                auth,
                saveAuth,
                currentUser,
                setCurrentUser,
                logout,
                login,
                getUser,
                searchUsers,
                register,
                createCompany,
                emailUserForSignUp,
                promoteUserToAdmin,
                deleteUser,
                getCompanies,
                getCompany,
                updateCompany,
                setFormsDone,
                toggleFormsDone,
                getCreds
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

function AuthInit({ children }: WithChildren) {
    const { auth, logout, setCurrentUser, getUser } = useAuth();
    const [showSplashScreen, setShowSplashScreen] = useState(true);

    useEffect(() => {
        /*
      if there is an auth token saved in local storage, attempt to get the user
      if not, logout user
    */
        if (auth && auth.api_token) {
            getUser()
                .then((res) => {
                    setCurrentUser(res[0]);
                    setShowSplashScreen(false);
                })
                .catch(() => {
                    logout();
                    setShowSplashScreen(false);
                });
        } else {
            logout();
            setShowSplashScreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
}

export { AuthProvider, AuthInit, useAuth };
