import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IMember } from '../../../models';
import { Button, Grid, InputLabel, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { updateCompanyMember } from '../../../shared';
import { toast } from 'react-toastify';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    member?: IMember
}

const initialValues: Partial<IMember> = {
    drivers_license_number: '',
    social_security_number: '',
    phone_number: '',
    state: '',
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateMemberModal({ open, setOpen, member }: IProps) {

    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            updateCompanyMember(String(member?.id), values).then(() => {
                resetForm()
                toast.success('Member Updated Successfully')
                setOpen(false)
            })
        }
    })

    const formikRef = useRef(formik)

    useEffect(() => {
        if (open && member) {
            formikRef?.current.setValues({
                drivers_license_number: member?.drivers_license_number,
                social_security_number: member?.social_security_number,
                state: member?.state
            })
        }
    }, [open, member])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{
                textAlign: 'center',
                borderBottom: '1px solid #e0e0e0',
            }}>
                Update {member?.user?.first_name} {member?.user?.last_name}'s Profile
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2} p={3}>
                        <Grid item xs={12}>
                            <InputLabel>
                                Phone Number
                            </InputLabel>
                            <TextField
                                value={formik.values.phone_number}
                                name='phone_number'
                                onChange={(e) => formik.setFieldValue('phone_number', e.target.value)}
                                fullWidth
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>
                                Drivers License Number
                            </InputLabel>
                            <TextField
                                value={formik.values.drivers_license_number}
                                name='drivers_license_number'
                                onChange={(e) => formik.setFieldValue('drivers_license_number', e.target.value)}
                                fullWidth
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>
                                Social Security Number
                            </InputLabel>
                            <TextField
                                value={formik.values.social_security_number}
                                name='social_security_number'
                                onChange={(e) => formik.setFieldValue('social_security_number', e.target.value)}
                                fullWidth
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>
                                State
                            </InputLabel>
                            <TextField
                                value={formik.values.state}
                                name='state'
                                onChange={(e) => formik.setFieldValue('state', e.target.value)}
                                fullWidth
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button size="small" fullWidth variant='contained' onClick={() => formik.handleSubmit()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
