import { Box, Grid, MenuItem, Paper, TableBody, TableCell, TablePagination, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { useAuth } from '../../modules/auth';
import {
    useGetDriverReportsQuery,
    useGetMaintenanceMemberDvirQuery
} from '../../../store/reducers/PreventableMaintenance/dvir.report';
import { IDVIReport } from '../../../store/interfaces/PreventableMaintenance/dvir.report';
import DateComponent from './DateComponent';
import SignatureField from './MaintenanceVehicleDetails/SignatureField';

export enum ReportsToLoad {
    AS_MECHANIC = 'AS_MECHANIC',
    AS_DRIVER = 'AS_DRIVER'
}

const OwnerDVIReportTable = () => {
    const { currentUser } = useAuth();
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [filter, setFilter] = useState<ReportsToLoad>(ReportsToLoad.AS_DRIVER);
    const [reportList, setReportList] = useState<IDVIReport[]>([]);
    const { data = [] } = useGetDriverReportsQuery(currentUser?.member?.id, {
        skip: !currentUser?.member?.id && filter !== ReportsToLoad.AS_DRIVER,
        refetchOnFocus: true
    });

    const { data: reports = [] } = useGetMaintenanceMemberDvirQuery(currentUser?.maintenance_member?.id || 0, {
        skip: !currentUser?.maintenance_member?.id && filter !== ReportsToLoad.AS_MECHANIC,
        refetchOnFocus: true
    });
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    useEffect(() => {
        if (filter === ReportsToLoad.AS_MECHANIC) {
            setReportList(reports);
        } else {
            setReportList(data);
        }
        // eslint-disable-next-line
    }, [data, reports, filter]);

    return (
        <>
            {currentUser?.member || currentUser?.maintenance_member ? (
                <Paper component={Box} mt={4}>
                    <TableWrapper>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle
                                title={
                                    filter === ReportsToLoad.AS_DRIVER
                                        ? "DVIR's completed by you"
                                        : "DVIR's Assigned to you as a Mechanic"
                                }
                            />
                        </TableContainerHeader>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    fullWidth
                                    value={filter}
                                    variant="outlined"
                                    size="small"
                                    select
                                    helperText="Filter Reports"
                                >
                                    {Object.values(ReportsToLoad).map((option: ReportsToLoad) => (
                                        <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                            {option === ReportsToLoad.AS_DRIVER ? 'Driver' : 'Mechanic'}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>File Name</TableHeaderColumn>
                                <TableHeaderColumn>Driver Name</TableHeaderColumn>
                                <TableHeaderColumn>Start Odometer Reading</TableHeaderColumn>
                                <TableHeaderColumn>End Odometer Reading</TableHeaderColumn>
                                <TableHeaderColumn>Mechanic / Next Driver Signature</TableHeaderColumn>
                                <TableHeaderColumn>Date of DVIR</TableHeaderColumn>
                            </TableHeader>
                            <TableBody>
                                {reportList?.slice(page * limit, page * limit + limit)?.map((dvir, index) => (
                                    <TableRow
                                        key={dvir.id}
                                        hover
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            window.location.assign(`/driver-inspection-report/${dvir?.id}`);
                                        }}
                                    >
                                        <TableCell>DVIR {index + 1}</TableCell>
                                        <TableCell>
                                            {dvir?.member?.user?.first_name ?? ''} {dvir?.member?.user?.last_name ?? ''}
                                        </TableCell>
                                        <TableCell>
                                            {Intl.NumberFormat('en-US', {
                                                compactDisplay: 'short'
                                            }).format(Number(dvir.start_odometer_reading))}{' '}
                                            Miles
                                        </TableCell>
                                        <TableCell>
                                            {Intl.NumberFormat('en-US', {
                                                compactDisplay: 'short'
                                            }).format(Number(dvir.end_odometer_reading))}{' '}
                                            Miles
                                        </TableCell>
                                        <SignatureField dvir={dvir} />
                                        <DateComponent date={dvir.date ?? new Date()} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={reportList.length}
                            page={page}
                            onPageChange={handlePageChange}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleLimitChange}
                            rowsPerPageOptions={[10, 25, 30]}
                        />
                    </TableWrapper>
                </Paper>
            ) : null}
        </>
    );
};

export default OwnerDVIReportTable;
