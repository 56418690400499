import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TransitionProps } from '@mui/material/transitions';
import { IMember } from '../../../../models';
import { Grid, InputLabel, TextField } from '@mui/material';
import { IManagerNotes, IManagerNotesDto } from '../../../../models/IMaanagerNotes';
import { useMutation, useQueryClient } from 'react-query';
import { createManagerNotes, updateManagerNotes } from '../../../../shared/client/manager-notes';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    member?: IMember;
    managerNote?: IManagerNotes;
    setManagerNote?: (managerNote?: IManagerNotes) => void;
}

const initialValues: IManagerNotesDto = {
    member: 0,
    company: 0,
    date: new Date(),
    time: new Date(),
    note_subject: '',
    note: ''
}

const validationSchema = Yup.object({
    note_subject: Yup.string().required('Required'),
    note: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    time: Yup.string().required('Required'),
})


export default function AddManagerNote({ open, setOpen, member, managerNote, setManagerNote }: Readonly<IProps>) {

    const queryClient = useQueryClient()

    const createManagerNoteMutation = useMutation(createManagerNotes, {
        onSuccess: () => {
            toast.success('Manager Note Added')
        },
    })

    const updateManagerNoteMutation = useMutation(updateManagerNotes, {
        onSuccess: () => {
            toast.success('Manager Note Updated')
        },
    })

    const handleAddManagerNote = async (values: IManagerNotesDto) => {
        const managerNote = await createManagerNoteMutation.mutateAsync(values)
        if (managerNote) {
            queryClient.invalidateQueries(['manager-notes', member?.user?.id])
            setOpen(false)
        }
    }

    const handleUpdateManagerNote = async (values: IManagerNotes) => {
        const managerNote = await updateManagerNoteMutation.mutateAsync(values)
        if (managerNote) {
            queryClient.invalidateQueries(['manager-notes', member?.user?.id])
            setOpen(false)
        }
    }

    const handleClose = () => {
        setManagerNote?.(undefined)
        formik.resetForm()
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: managerNote ?? initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (managerNote) {
                handleUpdateManagerNote({ ...values, id: managerNote.id }).then(() => {
                    handleClose()
                })
            } else {
                handleAddManagerNote({ ...values, member: Number(member?.id), company: Number(member?.company.id) }).then(() => {
                    handleClose()
                })
            }
        }
    })

    React.useEffect(() => {
        if (managerNote && open) {
            formik.setValues(managerNote)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, managerNote])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle style={{ textAlign: 'center' }}>{managerNote ? "Update Manager Note" : "Add Manager Note"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Date</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            type='date'
                            variant="outlined"
                            name='date'
                            value={formik.values.date}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Time</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            type='time'
                            variant="outlined"
                            name='time'
                            value={formik.values.time}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            size='small'
                            label="Subject of Note"
                            variant="outlined"
                            name='note_subject'
                            value={formik.values.note_subject}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            size='small'
                            label="Note"
                            variant="outlined"
                            name='note'
                            value={formik.values.note}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={() => formik.handleSubmit()}>
                            {managerNote ? "Update Manager Note" : "Add Manager Note"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
