import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getCompanyMember } from '../../../../../shared';
import { IMember } from '../../../../../models';
import { useGetMaintenanceVehiclesByCompanyQuery } from '../../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import {
    IMaintenanceVehicle,
    VehicleType
} from '../../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { useAuth } from '../../../../modules/auth';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { IDVIReport } from '../../../../../store/interfaces/PreventableMaintenance/dvir.report';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const getMostRecentDVIRReport = (vehicle: IMaintenanceVehicle): IDVIReport | null => {
    if (!vehicle.dvir_reports.length) return null;

    return vehicle.dvir_reports.reduce((mostRecent, current) => {
        return new Date(current.date!) > new Date(mostRecent.date!) ? current : mostRecent;
    });
};

export default function SelectVehicleModal({ open, setOpen }: IProps) {
    const { currentUser } = useAuth();
    const [id, setId] = React.useState<number>();
    const [vehicleNumber, setVehicleNumber] = React.useState<IMaintenanceVehicle>();
    const [selectedVehicleIncomplete, setSelectedVehicleIncomplete] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const { data } = useGetMaintenanceVehiclesByCompanyQuery(id ?? 0, {
        refetchOnFocus: true,
        skip: !id
    });

    const getCompanyId = async () => {
        if (currentUser?.role === 'Member') {
            const member = (await getCompanyMember(String(currentUser?.member?.id))) as unknown as IMember;
            setId(Number(member?.company?.id));
        }
        if (currentUser?.role === 'Owner') {
            setId(Number(currentUser?.company?.id));
        }
    };

    React.useEffect(() => {
        if (currentUser) {
            getCompanyId();
        }
        // eslint-disable-next-line
    }, [currentUser]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" textAlign="center" mb={4}>
                    Select Vehicle to Continue
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            id="vehicle_number"
                            type="text"
                            size="small"
                            select
                            value={vehicleNumber?.id ?? 0}
                            onChange={(e) => {
                                const vehicle = data?.find((item) => item.id === Number(e.target.value));
                                if (vehicle) {
                                    const latest_report = getMostRecentDVIRReport(vehicle);
                                    if (latest_report) {
                                        if (
                                            latest_report?.member?.user?.id === currentUser?.id &&
                                            !latest_report?.end_odometer_reading &&
                                            !latest_report?.next_driver_signature
                                        ) {
                                            setSelectedVehicleIncomplete(true);
                                            setMessage(
                                                `Please enter the end odometer reading and next driver's signature on previous DVIR for Vehicle #${vehicle?.vehicle_number} before starting a new one.`
                                            );
                                            setVehicleNumber(vehicle);
                                            return;
                                        }
                                        if (
                                            latest_report?.member?.user?.id === currentUser?.id &&
                                            !latest_report?.end_odometer_reading
                                        ) {
                                            setSelectedVehicleIncomplete(true);
                                            setMessage(
                                                `Please enter the end odometer reading on previous DVIR for Vehicle #${vehicle?.vehicle_number} before starting a new one.`
                                            );
                                            setVehicleNumber(vehicle);
                                            return;
                                        }
                                        if (
                                            !latest_report?.condition_satisfactory &&
                                            !latest_report.next_driver_signature
                                        ) {
                                            setSelectedVehicleIncomplete(true);
                                            setVehicleNumber(vehicle);
                                            setMessage(`Vehicle #(${vehicle?.vehicle_number}) has a previous DVIR that requires the next
                                driver’s signature. Please provide your signature on the previous DVIR and then you’ll
                                be able to start a new DVIR for this vehicle.`);
                                            return;
                                        }
                                        if (
                                            latest_report?.member?.user?.id !== currentUser?.id &&
                                            !latest_report?.end_odometer_reading
                                        ) {
                                            setSelectedVehicleIncomplete(false);
                                            setVehicleNumber(vehicle);
                                            setMessage(``);
                                            return;
                                        }
                                    }
                                }
                                setSelectedVehicleIncomplete(false);
                                setVehicleNumber(vehicle);
                            }}
                        >
                            {data
                                ?.filter((trailer) => trailer.vehicle_type !== VehicleType.TRAILER)
                                ?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item?.vehicle_make} {item?.vehicle_model} - {item?.vehicle_number}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            disabled={!vehicleNumber}
                            variant="contained"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => {
                                const report = getMostRecentDVIRReport(vehicleNumber!);
                                if (selectedVehicleIncomplete && report) {
                                    return window.location.assign(`/driver-inspection-report/${report.id}`);
                                }
                                return window.location.assign(`/driver-inspection-report/vehicle/${vehicleNumber?.id}`);
                            }}
                        >
                            Continue
                        </Button>
                        {selectedVehicleIncomplete && (
                            <Typography variant="caption" color="error">
                                {message}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
