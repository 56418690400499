import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { ICompany, IMember } from '../../../models'
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper'
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader'
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle'
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer'
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader'
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn'
import MemberRow from '../../../compliancemasters/partials/widgets/tables/MemberTable/components/MemberRow'
import { StripeProduct } from '../../modules/models'
import { getSubscriptionPlans } from '../../../shared'
import { Grid, Menu, MenuItem, TextField } from '@mui/material'
import { KTSVG as Ktsvg } from '../../../compliancemasters/helpers'
import UpdateMemberModal from './UpdateMemberModal'
import PreventableMaintenanceContainer from '../preventableMaintenance/PreventableMaintenanceContainer'

enum Filter {
    Active = "Active",
    Terminated = "Terminated"
}

const CompanyDetails = () => {
    const [filter, setFilter] = React.useState<Filter>(Filter.Active)
    const [userFilterInput, setUserFilterInput] = useState<string>("")
    const { id } = useParams<{ id: string }>()
    const [company, setCompany] = React.useState<ICompany>()
    const [loading, setLoading] = React.useState<boolean>(true)
    const { getCompany } = useAuth()
    const [subscriptions, setSubscriptions] = React.useState<StripeProduct[]>([])
    const [filteredMembers, setFilteredMembers] = React.useState<IMember[]>([])
    const [owner, setOwner] = React.useState<IMember>()
    const [member, setMember] = React.useState<IMember>()
    const [groupedData, setGroupedData] = React.useState<IMember[]>([])
    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [updateMemberModalOpen, setUpdateMemberModalOpen] = React.useState(false);
    const userMenuOpen = Boolean(userMenuAnchorEl);


    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    }

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    }

    React.useEffect(() => {
        const ownerAccount = filteredMembers?.find((x: IMember) => x.user.role === "Owner")
        setOwner(ownerAccount)
    }, [filteredMembers])

    const getSubs = React.useCallback(async () => {
        getSubscriptionPlans().then((res) => {
            const optionList = []
            for (const pkg of company?.package ?? []) {
                const option = res.data.find((x: any) => x.product_id === pkg.id)
                if (option) {
                    optionList.push(option)
                }
            }
            setSubscriptions(optionList)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, company?.members])

    React.useEffect(() => {
        getSubs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSubs])


    React.useEffect(() => {
        getCompany(Number(id)).then((res: ICompany) => {
            setCompany(res)
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (company?.members) {
            if (filter === Filter.Active) {
                setFilteredMembers(company?.members.filter((x: IMember) => !x.terminated_at))
            } else if (filter === Filter.Terminated) {
                setFilteredMembers(company?.members.filter((x: IMember) => x.terminated_at))
            }
        }
    }, [filter, company?.members])

    useEffect(() => {
        if (company?.members) {
            if (userFilterInput.length > 0) {
                setFilteredMembers(company?.members.filter((x: IMember) => `${x?.user?.first_name} ${x?.user?.last_name}`.toLowerCase().includes(userFilterInput.toLowerCase())))
            } else {
                setFilteredMembers(company?.members)
            }
        }
    }, [userFilterInput, company?.members])

    React.useEffect(() => {

        const groupedData = filteredMembers.reduce((groups: any, item) => {
            const product_id = item.product_id;

            if (!groups[product_id]) {
                groups[product_id] = [];
            }

            groups[product_id].push(item);
            return groups;
        }, {});

        // Sort each group by date_joined in descending order
        for (const key in groupedData) {
            if (groupedData.hasOwnProperty(key)) {
                groupedData[key].sort((a: any, b: any) => {
                    const dateA = new Date(a.user.created_at).getTime();
                    const dateB = new Date(b.user.created_at).getTime();
                    return dateB - dateA;
                });
            }
        }
        const members = Object.values(groupedData).flat() as IMember[]
        setGroupedData(members.filter((x: IMember) => filter === Filter.Active ? !x.terminated_at : x.terminated_at))

    }, [filteredMembers, filter])

    return (
        <div className='row gy-5 g-xl-8'>
            <div className='col-xl-12'>
                {!loading && company ? (
                    <TableWrapper>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle title="Employees" />
                        </TableContainerHeader>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField fullWidth value={filter} variant="outlined" size="small" select helperText="Filter Employees">
                                    {Object.values(Filter).map((option: Filter) => (
                                        <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField fullWidth value={userFilterInput} onChange={e => setUserFilterInput(e.target.value)} variant="outlined" size="small" placeholder="Search for Employees" />
                            </Grid>
                        </Grid>
                        {/* begin::Body */}
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>Employee</TableHeaderColumn>
                                <TableHeaderColumn>Driver Status / Screened</TableHeaderColumn>
                                <TableHeaderColumn>Date Screened</TableHeaderColumn>
                                <TableHeaderColumn>Confirmation Date</TableHeaderColumn>
                                <TableHeaderColumn>Status</TableHeaderColumn>
                                <TableHeaderColumn>Subscription</TableHeaderColumn>
                                <TableHeaderColumn end>Form Progress</TableHeaderColumn>
                            </TableHeader>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {owner && <MemberRow
                                    member={owner}
                                    key={owner?.id}
                                    product_name={subscriptions.find((x: any) => x.product_id === owner?.product_id)?.name}
                                    onActionComplete={() => getCompany(Number(id)).then((res: ICompany) => {
                                        setCompany(res)
                                        setLoading(false)
                                    })}
                                    setMember={setMember}
                                    setMenuOpen={handleUserMenuClick}
                                />}
                                {groupedData.filter((x) => x?.user?.role !== "Owner").sort((a, b) => new Date(b.created_at!).getMinutes() - new Date(a.created_at!).getMinutes())?.map((member: IMember) => {
                                    return <MemberRow
                                        member={member}
                                        key={member.id}
                                        product_name={subscriptions.find((x: any) => x.product_id === member.product_id)?.name}
                                        onActionComplete={() => getCompany(Number(id)).then((res: ICompany) => {
                                            setCompany(res)
                                            setLoading(false)
                                        })}
                                        setMember={setMember}
                                        setMenuOpen={handleUserMenuClick}
                                    />;
                                })}
                            </tbody>
                        </TableContainer>
                        {/* begin::Body */}
                    </TableWrapper>
                ) : null}
                {loading ? <div className="spinner spinner-primary spinner-lg mr-15"></div> : null}
            </div>
            <Menu
                anchorEl={userMenuAnchorEl}
                open={userMenuOpen}
                onClose={handleUserMenuClose}
            >
                <MenuItem onClick={() => {
                    handleUserMenuClose()
                    setUpdateMemberModalOpen(true)
                }}>
                    <div
                    >
                        <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Update Profile
                    </div>
                </MenuItem>
            </Menu>
            <UpdateMemberModal
                open={updateMemberModalOpen}
                setOpen={setUpdateMemberModalOpen}
                member={member}
            />
            <TableWrapper>
                <PreventableMaintenanceContainer />
            </TableWrapper>
        </div>
    )
}

export default CompanyDetails