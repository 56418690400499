import { useState } from 'react'
import { CompanyInvitation, CompanyInvitationContextProps, CompanyInvitationDto } from './company.invitation.types'
import { client } from '../../shared'
import { toast } from 'react-toastify'
import { ICompanyCreate } from '../../app/pages/dashboard/CreateCompanyModal'
import { IDriverUploadData } from '../../app/pages/drivers-bulk-upload/DriverBulkUpload'



const useCompanyInvitations = (): CompanyInvitationContextProps => {
    const [invitations, setInvitations] = useState<CompanyInvitation[]>([])
    const [invitation, setInvitation] = useState<CompanyInvitation>()
    const [invitationLoading, setInvitationLoading] = useState(false)

    const getInvitation = async (id: string): Promise<void> => {
        setInvitationLoading(true)
        try {
            const res = await client(`auth/api/company-invitation/get-company-invitation?email=${id}`)
            setInvitation(res)
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error getting invitation', error)
            toast.error('Error getting invitation')
            setInvitationLoading(false)
        }
    }

    const getInvitations = async (): Promise<void> => {
        setInvitationLoading(true)
        try {
            const res = await client('auth/api/company-invitation')
            setInvitations(res)
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error getting invitations', error)
            toast.error('Error getting invitations')
            setInvitationLoading(false)
        }
    }

    const createInvitation = async (invitation: CompanyInvitationDto): Promise<void> => {
        setInvitationLoading(true)
        try {
            const res = await client('auth/api/company-invitation', { body: invitation })
            setInvitations([...invitations, res])
            toast.success('Invitation created')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error creating invitation', error)
            toast.error('Error creating invitation')
            setInvitationLoading(false)
        }
    }

    const updateInvitation = async (invitation: Partial<CompanyInvitation>): Promise<void> => {
        setInvitationLoading(true)
        try {
            await client(`auth/api/company-invitation/${invitation.id}`, { body: invitation, method: 'PUT' })
            toast.success('Invitation updated')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error updating invitation', error)
            toast.error('Error updating invitation')
            setInvitationLoading(false)
        }
    }

    const deleteInvitation = async (id: number): Promise<void> => {
        setInvitationLoading(true)
        try {
            await client(`auth/api/company-invitation/${id}`, { method: 'DELETE' })
            setInvitations(invitations.filter((i) => i.id !== id))
            toast.success('Invitation deleted')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error deleting invitation', error)
            toast.error('Error deleting invitation')
            setInvitationLoading(false)
        }
    }

    const sendPaymentLink = async (email: string): Promise<void> => {
        try {
            await client(`auth/api/company-invitation/${email}/send-payment-link`, { method: 'POST' })
            toast.success('Payment link sent')
        } catch (error) {
            console.error('Error sending payment link', error)
            toast.error('Error sending payment link')
        }
    }

    const sendInvitation = async (email: string): Promise<void> => {
        try {
            await client(`auth/api/company-invitation/${email}/send-invitation`, { method: 'POST' })
            toast.success('Invitation sent')
        } catch (error) {
            console.error('Error sending invitation', error)
            toast.error('Error sending invitation')
        }
    }

    const createPaymentLink = async (email: string): Promise<void> => {
        try {
            await client(`auth/api/company-invitation/create-payment-link`, { method: 'POST', body: { email } })
            toast.success('Payment link created')
        } catch (error) {
            console.error('Error creating payment link', error)
            toast.error('Error creating payment link')
        }
    }

    const createCompanyProfile = async (data: ICompanyCreate): Promise<void> => {
        setInvitationLoading(true)
        try {
            await client(`auth/api/company-invitation/create-company/`, { method: 'POST', body: data })
            toast.success('Company created')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error creating company', error)
            toast.error('Error creating company')
            setInvitationLoading(false)
        }
    }

    const createBulkDrivers = async (drivers: IDriverUploadData[], company_id: number) => {
        setInvitationLoading(true)
        // const packages: { data: StripeProduct[] } = await getSubscriptionPlans()
        // const driverList = []
        // for (const driver of drivers) {
        //     const packageId = packages.data.find((p: StripeProduct) => p.name === driver.productId)?.product_id
        //     driverList.push({
        //         ...driver,
        //         productId: packageId
        //     })
        // }
        try {
            await client(`auth/api/company-invitation/create-bulk-drivers/`, { method: 'POST', body: { drivers, company_id } })
            toast.success('Drivers created')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error creating drivers', error)
            toast.error('Error creating drivers')
            setInvitationLoading(false)
        }
    }

    const inviteDrivers = async (email_list: string[]) => {
        setInvitationLoading(true)
        try {
            await client(`auth/api/company-invitation/invite-drivers/`, { method: 'POST', body: { email_list } })
            toast.success('Drivers invited')
            setInvitationLoading(false)
        } catch (error) {
            console.error('Error inviting drivers', error)
            toast.error('Error inviting drivers')
            setInvitationLoading(false)
        }
    }

    const memoizedValues = {
        invitations,
        invitation,
        invitationLoading,
        getInvitation,
        getInvitations,
        createInvitation,
        updateInvitation,
        deleteInvitation,
        sendPaymentLink,
        sendInvitation,
        createPaymentLink,
        createCompanyProfile,
        createBulkDrivers,
        inviteDrivers
    }

    return memoizedValues
}

export default useCompanyInvitations