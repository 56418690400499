import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    Slide,
    TextField
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect } from 'react';
import { IServiceTypes } from '../../../../store/interfaces/PreventableMaintenance/service.types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    useCreateServiceTypeMutation,
    useUpdateServiceTypeMutation
} from '../../../../store/reducers/PreventableMaintenance/service.types';
import { useCompany } from '../../../../context/CompanyContext';
import { useAuth } from '../../../modules/auth';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    service_type_count?: number;
    onCreate?: () => void;
    serviceType?: IServiceTypes;
    setServiceType?: (serviceType?: IServiceTypes) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getServiceName = (count: number) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const index = count % 26;
    const repeat = Math.floor(count / 26);
    return `${alphabet[index].repeat(repeat + 1)}`;
};

function getMilesArray(): number[] {
    const milesArray: number[] = [];
    for (let i = 1000; i <= 50000; i += 1000) {
        milesArray.push(i);
    }
    return milesArray;
}

const initialValues: Partial<IServiceTypes> = {
    name: '',
    description: '',
    cost: 0,
    miles: '0',
    pm_program: undefined,
    company: undefined
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    miles: Yup.string().required('Miles is required')
});

export const AddServiceTypeModal: React.FunctionComponent<IProps> = ({
    open,
    setOpen,
    vehicle,
    service_type_count = 0,
    onCreate,
    serviceType,
    setServiceType
}) => {
    const [createServiceType] = useCreateServiceTypeMutation();
    const [updateServiceType] = useUpdateServiceTypeMutation();
    const [customMiles, setCustomMiles] = React.useState<boolean>(false);
    const { currentCompany } = useCompany();
    const { currentUser } = useAuth();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (!serviceType) {
                createServiceType({
                    ...values,
                    company: Number(currentCompany?.id) ?? Number(currentUser?.company?.id),
                    maintenance_vehicle: Number(vehicle?.id),
                    pm_program: vehicle?.pm_program ? vehicle.pm_program : undefined
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                    setServiceType && setServiceType(undefined);
                    onCreate && onCreate();
                });
            }
            if (serviceType) {
                updateServiceType({
                    ...serviceType,
                    ...values,
                    company: Number(currentCompany?.id) ?? Number(currentUser?.company?.id),
                    maintenance_vehicle: Number(vehicle?.id),
                    pm_program: vehicle?.pm_program ? vehicle.pm_program : undefined
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                    setServiceType && setServiceType(undefined);
                    onCreate && onCreate();
                });
            }
        }
    });

    useEffect(() => {
        if (open && !serviceType) {
            formik.setFieldValue('name', `Service ${getServiceName(service_type_count)}`);
        }
        if (open && serviceType) {
            formik.setValues(serviceType);
        }
        if (!open) {
            formik.resetForm();
            setServiceType && setServiceType(undefined);
        }
        // eslint-disable-next-line
    }, [open, serviceType]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                {serviceType
                    ? `Update Service ${serviceType?.name}`
                    : `Add Service ${getServiceName(service_type_count)}`}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                size="small"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                disabled
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        {!customMiles && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="miles"
                                    name="miles"
                                    label="Miles"
                                    size="small"
                                    value={formik.values.miles}
                                    select
                                    onChange={formik.handleChange}
                                    error={formik.touched.miles && Boolean(formik.errors.miles)}
                                    helperText={formik.touched.miles && formik.errors.miles}
                                >
                                    <MenuItem value={'0'}>
                                        {serviceType
                                            ? serviceType?.name
                                            : `Service ${getServiceName(service_type_count)}`}{' '}
                                        will occur every _____________ miles
                                    </MenuItem>
                                    {getMilesArray().map((mile, index) => (
                                        <MenuItem key={index} value={mile.toString()}>
                                            {serviceType
                                                ? serviceType?.name
                                                : `Service ${getServiceName(service_type_count)}`}{' '}
                                            will occur every {mile} miles
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}
                        {customMiles && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="miles"
                                    name="miles"
                                    label="Miles"
                                    size="small"
                                    value={formik.values.miles}
                                    onChange={formik.handleChange}
                                    error={formik.touched.miles && Boolean(formik.errors.miles)}
                                    helperText={formik.touched.miles && formik.errors.miles}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            m={1}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <FormControlLabel
                                control={<Checkbox checked={customMiles} />}
                                label={`Add a Custom Mileage Interval for Service ${
                                    serviceType ? serviceType?.name : getServiceName(service_type_count)
                                }`}
                                onChange={() => setCustomMiles(!customMiles)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="description"
                                name="description"
                                label={`Enter a description of the maintenance services you want to be reminded of for Service ${getServiceName(
                                    service_type_count
                                )} for this vehicle`}
                                size="small"
                                multiline
                                rows={4}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" size="small" fullWidth>
                                {serviceType ? 'Update' : 'Add Service'}{' '}
                                {serviceType ? serviceType?.name : getServiceName(service_type_count)}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
