import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import { KTSVG } from '../../../../compliancemasters/helpers';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { MoreVertRounded } from '@mui/icons-material';
import { INotesAndLinks } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import { AddLinkModal } from './AddLinkModal';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import DeleteNoteModal from './DeleteNoteModal';

interface IProps {
    notesAndLinks: INotesAndLinks[];
    vehicle?: IMaintenanceVehicle;
}

const LinksTable: FC<IProps> = ({ notesAndLinks, vehicle }) => {
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [addLinksOpen, setAddLinksOpen] = useState(false);
    const [note, setNote] = useState<INotesAndLinks>();
    const [deleteNotesModalOpen, setDeleteNotesModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };
    return (
        <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                        Vehicle Links
                    </Typography>
                </Grid>
            </Grid>
            <TableWrapper>
                <TableContainerHeader>
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setAddLinksOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Link
                    </div>
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>#</TableHeaderColumn>
                        <TableHeaderColumn>Link</TableHeaderColumn>
                        <TableHeaderColumn>Link Description</TableHeaderColumn>
                        <TableHeaderColumn>{''}</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {notesAndLinks?.slice(page * limit, page * limit + limit).map((note, index) => (
                            <TableRow hover sx={{ cursor: 'pointer' }} key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <a href={note.link} target="_blank" rel="noreferrer">
                                        {note.link}
                                    </a>
                                </TableCell>
                                <TableCell>{note.link_description}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={(e) => {
                                            setNote(note);
                                            handleClick(e);
                                        }}
                                    >
                                        <MoreVertRounded />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={notesAndLinks.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 30]}
                />
            </TableWrapper>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        setAddLinksOpen(true);
                        handleClose();
                    }}
                >
                    View / Update Note
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDeleteNotesModalOpen(true);
                        handleClose();
                    }}
                >
                    Delete Note
                </MenuItem>
            </Menu>
            <DeleteNoteModal
                open={deleteNotesModalOpen}
                setOpen={setDeleteNotesModalOpen}
                note={note}
                setNote={setNote}
            />
            <AddLinkModal open={addLinksOpen} setOpen={setAddLinksOpen} vehicle={vehicle} />
        </Box>
    );
};

export default LinksTable;
