/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSub } from '../AsideMenuItemWithSub';
import { AsideMenuItem } from '../AsideMenuItem';
import { useCompany } from '../../../../../context/CompanyContext';
import { StripeProduct } from '../../../../../app/modules/models';
import { StripeActions } from '../../../../../store/reducers/StripeProducts/StripeActions';
import { useSelector } from '../../../../../store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PostHogFeature, useFeatureFlagPayload } from 'posthog-js/react';

interface IPayloadProps {
    account_roles: string[];
    enabled_for: string[];
}

export function OwnerAsideMenu() {
    const { currentCompany } = useCompany();
    const [products, setProducts] = React.useState<StripeProduct[]>([]);
    const [showMvr, setShowMvr] = React.useState<boolean>(false);
    const [showDrug, setShowDrug] = React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const payload = useFeatureFlagPayload('pm-program-enabled') as unknown as IPayloadProps | undefined;
    const intl = useIntl();
    const {
        product: { products: stProducts },
        companyAddons: { companyAddon: addons }
    } = useSelector((state) => state.stripeReducer);

    const getPlanOptions = React.useCallback(async () => {
        const optionList: StripeProduct[] = [];
        for (const pkg of currentCompany?.package ?? []) {
            const option = stProducts.find((x: any) => x.product_id === pkg.id);
            if (option) {
                optionList.push(option);
            }
        }
        setProducts(optionList);
    }, [currentCompany?.package, stProducts]);

    const packageExists = (packageName: string) => {
        return products.some((pkg) => pkg.name === packageName);
    };

    React.useEffect(() => {
        StripeActions.getProducts();
        StripeActions.getCompanyAddons();
        StripeActions.getCompanySubscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        getPlanOptions();
    }, [getPlanOptions]);

    React.useEffect(() => {
        if (products.length > 0) {
            if (packageExists('Master Package') || addons.some((addon) => addon.name === 'Live MVR add-on')) {
                setShowMvr(true);
            } else {
                setShowMvr(false);
            }
            if (packageExists('Master Package') || addons.some((addon) => addon.name === 'D&A Written Policy')) {
                setShowDrug(true);
            } else {
                setShowDrug(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, addons]);

    return (
        <>
            <AsideMenuItem
                to="/dashboard"
                icon="/media/icons/duotune/art/art002.svg"
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/expiring-files"
                icon="/media/icons/duotune/files/fil012.svg"
                title="Expiring Files"
                fontIcon="bi-app-indicator"
            />

            {showMvr ? (
                <AsideMenuItem
                    to="/live-mvr-monitoring"
                    icon="/media/icons/duotune/art/art006.svg"
                    title={'Live MVR Monitoring'}
                    fontIcon="bi-app-indicator"
                />
            ) : null}

            {showDrug && (
                <AsideMenuItem
                    to="/drug-alcohol-written-policy"
                    icon="/media/icons/duotune/art/art006.svg"
                    title={'Drug and Alcohol Written Policy'}
                    fontIcon="bi-app-indicator"
                />
            )}

            <AsideMenuItemWithSub
                to="/subscription/payment"
                title="Billing"
                fontIcon="bi-archive"
                icon="/media/icons/duotune/general/gen022.svg"
            >
                <AsideMenuItem to="subscription/plan" title="Subscription Plan" hasBullet={true} />
                <AsideMenuItem to="subscription/invoices" title="Invoices" hasBullet={true} />
            </AsideMenuItemWithSub>
            {packageExists('Master Package') ||
            packageExists('Deluxe Package') ||
            packageExists('Drug and Alcohol Package') ||
            packageExists('Deluxe Mini Package') ? (
                <AsideMenuItem
                    title={'Random Drug and Alcohol Testing Selections'}
                    to="/random-testing-selections"
                    icon="/media/icons/duotune/art/art010.svg"
                    fontIcon="bi-app-indicator"
                />
            ) : null}
            {/* <PostHogFeature flag='pm-program-enabled' match={true}>
                {payload?.enabled_for?.includes(process.env.REACT_APP_PUBLIC_ENV as string) && ( */}
            {/* <AsideMenuItem
                to="/preventable-maintenance"
                icon="/media/icons/duotune/files/fil012.svg"
                title="Preventable Maintenance (PM) Program"
                fontIcon="bi-app-indicator"
            /> */}
            {/* )}
            </PostHogFeature> */}
        </>
    );
}
