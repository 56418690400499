import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TransitionProps } from '@mui/material/transitions';
import { IMember } from '../../../../models';
import { Grid, InputLabel, TextField } from '@mui/material';
import { IManagerNotes, IManagerNotesDto } from '../../../../models/IMaanagerNotes';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    member?: IMember;
    managerNote?: IManagerNotes;
    setManagerNote?: (managerNote?: IManagerNotes) => void;
}

const initialValues: IManagerNotesDto = {
    member: 0,
    company: 0,
    date: new Date(),
    time: new Date(),
    note_subject: '',
    note: ''
}

const validationSchema = Yup.object({
    note_subject: Yup.string().required('Required'),
    note: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    time: Yup.string().required('Required'),
})



export default function ViewManagerNoteModal({ open, setOpen, managerNote, setManagerNote }: Readonly<IProps>) {

    const handleClose = () => {
        setManagerNote?.(undefined)
        formik.resetForm()
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: managerNote ?? initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

        }
    })

    React.useEffect(() => {
        if (managerNote && open) {
            formik.setValues(managerNote)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, managerNote])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle style={{ textAlign: 'center' }}>View Note</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Date</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            type='date'
                            variant="outlined"
                            name='date'
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Time</InputLabel>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            type='time'
                            variant="outlined"
                            name='time'
                            value={formik.values.time}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            size='small'
                            label="Subject of Note"
                            variant="outlined"
                            name='note_subject'
                            value={formik.values.note_subject}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            disabled
                            multiline
                            rows={4}
                            fullWidth
                            size='small'
                            label="Note"
                            variant="outlined"
                            name='note'
                            value={formik.values.note}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
