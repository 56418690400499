import { Box, Paper, TableBody, TableCell, TablePagination, TableRow } from '@mui/material';
import React, { FC } from 'react'
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { IServiceAlerts } from '../../../store/interfaces/ServiceAlerts/ServiceAlerts.interface';

interface IProps {
    serviceAlerts: IServiceAlerts[]
}

const ServiceAlertsTable: FC<IProps> = ({ serviceAlerts }) => {
    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(5);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setLimit(parseInt(event.target.value));
    };
    return (
        <Paper component={Box} mt={4}>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title='Service Alerts' />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>
                            #
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Vehicle No.
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Service Reminder
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Description
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Date of Reminder
                        </TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {serviceAlerts.slice(page * limit, page * limit + limit).map((serviceAlert, index) => (
                            <TableRow key={serviceAlert.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{serviceAlert?.vehicle.vehicle_number}</TableCell>
                                <TableCell>{serviceAlert?.service.name}</TableCell>
                                <TableCell>{serviceAlert.description}</TableCell>
                                <TableCell>{Intl.DateTimeFormat('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: '2-digit'
                                }).format(new Date(serviceAlert.date_of_reminder))}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={serviceAlerts.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 30]}
                    />
                </Box>
            </TableWrapper>
        </Paper>
    )
}

export default ServiceAlertsTable