import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils';
import { WithChildren } from '../../compliancemasters/helpers';
import Profile from '../modules/profile/CompanyMemberProfile';
import SuperAdminDashboard from '../pages/dashboard/SuperAdminDashboard';
import MemberRequestDashboard from '../pages/requests/MemberRequestDashboard';
import AddAdmin from '../../compliancemasters/partials/widgets/tables/MemberTable/AddAdmin';
import CompanyDetails from '../pages/dashboard/CompanyDetails';
import PreEmploymentRequestDashboard from '../pages/pre-employment-requests/PreEmploymentRequestDashboard';
import ExpiringFilesDashboard from '../pages/expiring-files/ExpiringFilesDashboard';
import SalesRepsContainer from '../pages/sales-reps/SalesRepsContainer';
import SalesRepDetails from '../pages/sales-reps/SalesRepDetails';
import RandomTestingSelections from '../pages/random-testing-selections/RandomTestingSelections';
import RandomTestingSelectionDetails from '../pages/random-testing-selections/RandomTestingSelectionDetails';
import ComplianceMasterSalesRepDetails from '../pages/sales-reps/ComlianceMasterSalesRepDetail';
import TrainingContainer from '../pages/sales-rep-training/TrainingContainer';
import DriverBulkUpload from '../pages/drivers-bulk-upload/DriverBulkUpload';
import UserRoles from '../pages/user-roles/UserRoles';
import DvirRoutes from './DvirRoutes';
import MaintenanceVehicleDetails from '../pages/preventableMaintenance/MaintenanceVehicleDetails/MaintenanceVehicleDetails';

export default function SuperAdminPrivateRoutes() {
    const ProfilePage = lazy(() => import('../modules/profile/CompanyMemberProfile'));
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
    const ParentPaymentPage = lazy(() => import('../modules/subscription/ParentPaymentPage'));

    return (
        <Routes>
            {/* Pages */}
            <Route path="dashboard" element={<SuperAdminDashboard />} />
            <Route path="memberrequests" element={<MemberRequestDashboard />} />
            <Route path="company/:id" element={<CompanyDetails />} />
            <Route path="admin/add" element={<AddAdmin />} />
            <Route path="add-drivers/:id" element={<DriverBulkUpload />} />
            <Route path="menu-test" element={<MenuTestPage />} />
            <Route path="preemployment-requests" element={<PreEmploymentRequestDashboard />} />
            <Route path="expiring-files" element={<ExpiringFilesDashboard />} />
            <Route path="user-roles" element={<UserRoles />} />
            <Route path="sales-reps" element={<SalesRepsContainer />} />
            <Route path="sales-rep-training" element={<TrainingContainer />} />
            <Route path="sales-reps/:id" element={<SalesRepDetails />} />
            <Route path="compliance-sales-reps/:id" element={<ComplianceMasterSalesRepDetails />} />
            <Route path="random-testing-selections" element={<RandomTestingSelections />} />
            <Route path="random-testing-selections/:id" element={<RandomTestingSelectionDetails />} />
            <Route
                path="driver-inspection-report/*"
                element={
                    <DvirRoutes />
                }
            />
            <Route
                path="maintenance-vehicle/:id/*"
                element={
                    <SuspensedView>
                        <MaintenanceVehicleDetails />
                    </SuspensedView>
                }
            />
            {/* Lazy Modules */}
            <Route
                path="crafted/pages/profile/*"
                element={
                    <SuspensedView>
                        <ProfilePage />
                    </SuspensedView>
                }
            />
            <Route
                path="crafted/pages/wizards/*"
                element={
                    <SuspensedView>
                        <WizardsPage />
                    </SuspensedView>
                }
            />
            <Route
                path="crafted/widgets/*"
                element={
                    <SuspensedView>
                        <WidgetsPage />
                    </SuspensedView>
                }
            />
            <Route
                path="crafted/account/*"
                element={
                    <SuspensedView>
                        <AccountPage />
                    </SuspensedView>
                }
            />
            <Route
                path="apps/chat/*"
                element={
                    <SuspensedView>
                        <ChatPage />
                    </SuspensedView>
                }
            />
            <Route
                path="apps/user-management/*"
                element={
                    <SuspensedView>
                        <UsersPage />
                    </SuspensedView>
                }
            />
            <Route
                path="subscription/*"
                element={
                    <SuspensedView>
                        <ParentPaymentPage />
                    </SuspensedView>
                }
            />
            <Route path="member/:memberId/profile/*" element={<Profile />} />
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    );
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
